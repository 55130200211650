import React from 'react';
import autoBind from 'react-autobind';
import SearchBar from './search-bar';
import styles from './demo.css';
import words from './words.json';
import productsName from './products';

class Demo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      products: []
    };

    autoBind(this, 'handleChange', 'handleClear', 'handleSelection');
  }

  handleClear() {
    this.setState({
      suggestions: []
    });
  }

  handleChange(input) {
    //startsWith
    this.setState({
      suggestions: productsName.filter(word => word.includes(input))
    });
  }

  handleSelection(value) {
    if (value) {

      this.props.onNewItem(value);
      this.handleClear();
      //console.info(`Selected "${value}"`);
    }
  }

  handleSearch(value) {
    if (value) {
      console.info(`Searching "${value}"`);
    }
  }

  suggestionRenderer(suggestion, searchTerm) {
    return (
      <span>
      
        <strong>{suggestion}</strong>
      </span>
    );
  }

  render() {
    return (
      <SearchBar
        autoFocus
        renderClearButton
        renderSearchButton
        placeholder="Buscar Productos"
        onChange={this.handleChange}
        onClear={this.handleClear}
        onSelection={this.handleSelection}
        onSearch={this.handleSearch}
        shouldRenderClearButton={false}
        shouldRenderSearchButton={false}
        suggestions={this.state.suggestions}
        suggestionRenderer={this.suggestionRenderer}
        styles={styles}
      />
    );
  }
}

export default Demo;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import ProductItem from './ProductItem';
import Demo from './demo';

class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      items:  [],
      total: 0,
      products: {},
      restoredItems: [],
      restoredId: 0
     
    };
  }

  getItems = async() => {
    const response = await axios.get('http://192.168.100.129/api.php')
    .then((res) => {

    	this.setState({ items: res.data.items });
    	//console.log(res.data);

    }).catch((err) => {
      console.log(err);
    });
  }

  saveItems = async() => {

    if (this.state.items.length === 0) {
      alert('No tienes Articulos seleccionados para guardar');
      document.getElementById('search').focus();
      return;
      
    }

    const items = {
      items: this.state.items
    };

    const response = await axios.post('https://sistema.megaplus.com.do/api/cafeteria/items',JSON.stringify(items))
    .then((res) => {


      //console.log('ITEMS SAVED ON CLOUD', items);
    
      if (!window.confirm(`Articulo(s) guardado(s) correctamente. Deseas limpiar la pantalla?`)) {
        document.getElementById('search').focus();
        return;
      }

      this.setState({ items: []});
      setTimeout(this.sumTotalItems,200); 
      document.getElementById('search').focus();

    }).catch((err) => {
      console.log(err);
    });
  }

  getProducts = async() => {
    const response = await axios.get('https://sistema.megaplus.com.do/api/cafeteria/productos')
    .then((res) => {

      this.setState({ products: res.data });
      //console.log(res.data);

    }).catch((err) => {
      console.log(err);
    });
  }

  hadleNewItem = (description) => {
    //console.log('PRODUCT TO FIND', description.toUpperCase());
  	const tempItems = [...this.state.items];

    const product = this.state.products.productoInfo[this.state.products.productoInfo.findIndex((p) => p.pdesc === description.toUpperCase())];
  	
    const  check = tempItems.filter((i) => i.desc === description.toUpperCase());
    if (check.length > 0) {
      alert('Ya introduciste este producto anteriormente!');
      document.getElementById('search').focus();
      return;
    }

  	tempItems.push({
  		id: product.id,
  		desc: product.pdesc,
  		qty: 1,
  		price: product.price,
  		total: product.price
  	});

  	this.setState({ items: tempItems });

    //console.log('TEMP ITEMS',tempItems);  
    setTimeout(this.sumTotalItems,200);  
    document.getElementById('search').focus();

  }

  handleDeleteItem = (id, desc = null) => {
    //console.log('PRODUCT TO FIND', id, desc);

    if (!window.confirm(`Esta seguro de borrar el producto ${desc}?`)) {
      return;
    }
   
    const items = this.state.items.filter((i) => i.id !== id);
   
    this.setState({ items });
    //console.log('ITEM TO DELETE',id);
    //console.log('ITEMS AFTER DELETE',items);
 
    setTimeout(this.sumTotalItems,200);  
  }



  sumTotalItems = () => {

  	if (this.state.items.length > 0) {

  		const sum = this.state.items.map(item => item.total).reduce((prev, next) => prev + next);	
  		this.setState({ total: sum });
  		//console.log('TOTAL',sum);

  	} else {

      this.setState({ total: 0 });

    }
  	
  }

  handleItemsChange = (item) => {
  	//console.log('CURRENT ITEM',item);
  	/*const tempItem = this.state.items.filter((i) => i.id === item.id );
  	*/
  	const items = [...this.state.items];
  	const index = items.findIndex( i => i.id === item.id );
  	items[index] = { ...item };
  	const sum = items.map(item => item.total).reduce((prev, next) => prev + next);	
  	this.setState({ items, total: sum });
  	setTimeout(this.sumTotalItems,1000);
  		
  }

  retoreItems = async () => {

    const response = await axios.get('https://sistema.megaplus.com.do/api/cafeteria/items')
    .then((res) => {
      const items = res.data.itemsInfo;
      this.setState({ restoredItems: items });
      //const restored = JSON.parse(items.items_json);
      console.log('ALL RECORDS RESTORED',items);

    }).catch((err) => {
      console.log(err);
    });

  }

  restoreItem = async (r = 0) => {

    if (this.state.items.length > 0 && parseInt(r) < 100000) {

      if (!window.confirm(`Tienes productos pendientes en pantalla, deseas restaurar sin guardar?`)) {
        document.getElementById('search').focus();
        return;
      }

    }

    if (parseInt(r) === 100000) {
      return;
    }

    this.setState({ items: [] });

     const response = await axios.get('https://sistema.megaplus.com.do/api/cafeteria/items')
    .then((res) => {

      //this.setState({ items: res.data.itemsInfo[record] });
      const items = res.data.itemsInfo[parseInt(r)];
      const restored = JSON.parse(items.items_json);
      //this.setState({ restoredItems: parseInt(items.id) });
      //console.log('ME',restored);

      /////

      restored.items.map((r) => {

          let tempItems = [...this.state.items];

    tempItems.push({
      id: r.id,
      desc: r.desc,
      qty: r.qty,
      price: r.price,
      total: parseInt(r.qty) * parseFloat(r.price)
    });

    this.setState({ items: tempItems });

 setTimeout(this.sumTotalItems,200); 
      });

      
      ////

    }).catch((err) => {
      console.log(err);
    });

    /*const date = Date.now().toString();

    const items = {
     
     [date]: this.state.items
      
    };

    const itemsArray = [items];
    //console.log(localStorage.getItem('items'));
    
    if (localStorage.getItem('items') !== null) {
      
      const jsonStorage = JSON.parse(localStorage.getItem("items"));
      const tempItems = [...jsonStorage];
      //console.log('STORAGE ITEMS',tempItems);
      tempItems.push(items);
      
     
      localStorage.setItem("items", JSON.stringify(tempItems));
      //console.log('AFTER STORAGE ITEMS',JSON.parse(localStorage.getItem('items')));
    
    } else {

      localStorage.setItem("items", JSON.stringify(itemsArray));
    
    }*/
    
  }

  deleteItem = async (item_id) => {

   if (!window.confirm(`Esta seguro de Eliminar este regristro?`)) {
      document.getElementById('search').focus();
      return;
    }

    console.log('REGISTRO A ELIMINAR', item_id);
    const response = await axios.delete('https://sistema.megaplus.com.do/api/cafeteria/items', 
    {id: parseInt(item_id)})
    .then((res) => {

      if (res.data.result === true) {

         this.setState({ items: [] });
         alert('Registro eliminado correctamente');
         return;

      } else {

        alert('No se pudo eliminar el registro');
        return;

      }
     


    }).catch((err) => {
      console.log(err);
    });

  }

  clearItems = () => {

    if (this.state.items.length > 0 ) {

      if (!window.confirm(`Esta seguro de Limpiar la pantalla?`)) {
        document.getElementById('search').focus();
        return;
      }

    }
    
    
    this.setState({ items: []});
    setTimeout(this.sumTotalItems,200); 
    document.getElementById('search').focus();
  }

  componentDidMount() {
    this.getProducts();
    this.retoreItems();

    setTimeout(this.sumTotalItems,1000);
   
  }

  getDateData = (date) => {
   const d = new Date(date);
   const monthNames = ["Enero", "Febero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
]; 
  
  const ret = d.getDay().toString()+" "+monthNames[d.getMonth()]+" "+d.getFullYear().toString()+" "+d.getHours().toString()+":"+d.getMinutes().toString()+":"+d.getSeconds().toString();


  return ret;
  }

  render() {
  	//console.log(this.state.items);

    let dropdownRestored = null;
    let d = null;
    if (this.state.restoredItems.length > 0) { 
      dropdownRestored = this.state.restoredItems.map((items, index) => (
      
      <option value={index}>{ `Articulo del ${this.getDateData(items.created_at)}` }</option>
     
      ));

    }


    let itemDeleteButton = null;

    if (this.state.restoredId > 0) {
      itemDeleteButton = (<button onClick={() => this.deleteItem(this.state.restoredId)}>Eliminar Regristro</button>)
    }

    let thead = null;

    if (this.state.items.length > 0) {
      thead = (
        <thead>
      <tr>
      <th>NOMBRE PRODUCTO</th>
      <th>CANTIDAD</th>
      <th>PRECIO</th>
      <th>IMPORTE</th>
      <th>ACCION</th>
      </tr>
      </thead>
        );
    }
  	
  
    return (
      <>
    <header>

      <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <h1 class="navbar-brand" style={{display: "block",float:"left"}}>&nbsp;<a href="" style={{textDecoration:"none",color:"#fff",fontWeight:"bold"}}>Sistema de Cafeteria</a> |&nbsp;</h1>
        <h1 class="navbar-brand" style={{display: "block",float:"right"}}><b>TOTAL: RD$ {parseFloat(this.state.total).toLocaleString() + '.00'}</b></h1>
        &nbsp;|<button onClick={this.saveItems}>Guardar Articulos</button>&nbsp;|&nbsp;<button onClick={this.clearItems}>Limpiar Pantalla</button>&nbsp;|&nbsp;<select onChange={(e)=>this.restoreItem(e.target.value)}><option value="100000">Seleccionar Articulos Guardados</option>{dropdownRestored}</select>
        <div class="collapse navbar-collapse" id="navbarCollapse">
         
        </div>
      </nav>
    </header>

       <main role="main" class="container">
  <br/><br/><br/><br/>
    
      <Demo class="form-control mr-sm-20" onNewItem={this.hadleNewItem} />
 
      <br/><br/>
     
      <center>
      <table border="1" className="table">
      {thead}
      <tbody>
        {this.state.items.map((items, index) => (
          <ProductItem key={index} item={items} onItemChange={this.handleItemsChange} onDeleteItem={this.handleDeleteItem} />
        ))
        }
        </tbody>
        </table>
        <br/><br/> 
        {itemDeleteButton} 
        </center>
        <br/>
    </main>
   
      </>
    );
  }

  

}

export default App;
import React from 'react';

class ProductItem extends React.Component {
  constructor(props){
  	super(props);
    const { item, onItemChange } = this.props;
  	this.state = {
  		id : item.id,
  		desc: item.desc,
  		qty: item.qty,
  		price: item.price,
  		total: parseInt(item.qty) * parseFloat(item.price)
  	};
  }
 
  handleItemsDesc = (desc) => {
  	const { onItemChange } = this.props;

  	//const itotal = parseInt(this.state.qty) * parseFloat(this.state.price);
  	//this.setState({ total: itotal });
  	const item = {
  		id: this.state.id,
  		desc,
  		qty: parseInt(this.state.qty),
  		price: parseFloat(this.state.price),
  		total: parseFloat(this.state.total)
  	};

  	onItemChange(item);
  }

  handleItemsQty = (qty) => {
  	const { onItemChange } = this.props;
  	const itotal = parseInt(qty) * parseFloat(this.state.price);
  	this.setState({ total: itotal });
  	const item = {
  		id: this.state.id,
  		desc: this.state.desc,
  		qty: parseInt(qty),
  		price: parseFloat(this.state.price),
  		total: parseFloat(itotal)
  	};

  	onItemChange(item);
  }

  handleItemsPrice = (price) => {
  	const { onItemChange } = this.props;
  	const itotal = parseFloat(parseInt(this.state.qty) * parseFloat(price));
  	this.setState({ total: itotal });
  	const item = {
  		id: this.state.id,
  		desc: this.state.desc,
  		qty: parseInt(this.state.qty),
  		price: parseFloat(price),
  		total: parseFloat(itotal)
  	};

  	onItemChange(item);
  }

  handleDeleteItem = (id, desc = null) => {
    const { onDeleteItem } = this.props;
    
    console.log('DELETE ITEM', id);
    document.getElementById('search').focus();
    onDeleteItem(id, desc);
  }

  
  setDesc = (e) => {

  	const value = e.target.value;
  	this.setState({ desc: value });
  	this.handleItemsDesc(value);

  }

  setQty = (e) => {

  	const value = e.target.value || 0;

  	this.setState({ qty: value });
  	this.handleItemsQty(value);
  	
  }

  setPrice = (e) => {

  	const value = e.target.value || 0;
  	this.setState({ price: value });
  	this.handleItemsPrice(value);
  	
  }

  setTotal = (e) => {

  	const value = e.target.value;
  	this.setState({ total: value });
  	//this.handleItemsTotal(value);
  	
  }

  render() {
  	return(
    <>
    <tr>
    <td>
      <input 
        placeholder = "Descripcion"
        value = {this.state.desc}
        onChange = {this.setDesc}
        name="desc[]"

      />
      </td>
      <td>
      <input 
      	type="number"
        placeholder = "Cantidad"
        min="1"
        value = {this.state.qty}
        onChange = {this.setQty}
        name="qty[]"
        style={{width:"200px"}}
      />
      </td>
      <td>
      <input 
        type="number"
        min="1"
        placeholder = "Precio"
        value = {this.state.price}
        onChange = {this.setPrice}
        name="price[]"
        style={{width:"200px"}}
      /> 
      </td>
      <td>
      <input 
        placeholder = "Total"
        value = {this.state.total}
        onChange = {this.setTotal}
        name="total[]"
        style={{width:"200px"}}
        readOnly
      />
      </td>
      <td>
      <button className="btn btn-danger btn-sm" onClick={() => this.handleDeleteItem(this.state.id, this.state.desc)}>Borrar</button>
      </td>
      </tr>
     
    </>
  );

  }

}

ProductItem.defaultProps = {
  isRetored: false
};

export default ProductItem;
import axios from 'axios';

const productsName =  [];

const getProducts = async() => {
     await axios.get('https://sistema.megaplus.com.do/api/cafeteria/productos')
    .then((res) => {

    	res.data.productoInfo.map((producto) => {
    		productsName.push(producto.pdesc.toLowerCase());
    	});

    	console.log(productsName);

    }).catch((err) => {
      console.log(err);
    });
}

getProducts();

export default productsName;

